import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";

const items = [
  {
    title: "Á la carte",
    image: "images/banner1.png",
    desc: `<p>Vi erbjuder våra gäster en först klassisk indisk middag direkt från kulturen i Indien. Vi serverar våra gäster den typen av mat som vi är uppvuxna med. Kom in och njut av en gastronimisk resa till Indien och välj mat och dryck från vår fantastiska meny.</p>
    <p><b>15% rabatt under hela januari och feburari månad (Ala Carte och Take away). <br/>Gäller ej alkohol/drycker</b></p>`,
    link: "/alacarte"
  },
  {
    title: "Lunch och Take away",
    image: "images/banner2.png",
    desc: `<p>Lunchen serveras vardagar kl. 11:00-15:00</p><p>115 SEK (inkl. sallad, naan och kaffe)</p>
    <p>Vi erbjuder avhämtning alla dagar i veckan. Det brukar ta 10-15 minuter för maten att bli klar.</p>
    <p><b>15% rabatt under hela januari och feburari månad (Ala Carte och Take away). <br/>Gäller ej alkohol/drycker</b></p>`,
    link: "/lunch"
  },
  {
    title: "Nyheter och Evenemang",
    image: "images/banner3.png",
    desc: `<p><b>15% rabatt på hela notan under hela januari och feburari månad.<br/> Gäller endast Ala Carte och Take away meny. <br/> Gäller ej alkohol/drycker</b></p> 
    <p>Skall du ha fest, event eller middag med familjen och vill bjuda på något som får gästerna att tänka WOW!</p>
    <p>Då är det bara att kontakta oss på 031- 13 53 75 och prata med vår personal.</p>`
  }
];
const BoxItem = props => {
  return (
    <div className="row">
      {items.map((item, index) => {
        return (
          <div className="span3" key={index}>
            <div className="banner1">
              <a href="/#">
                <div className="banner_top"></div>
                <div className="banner_inner">
                  <img src={item.image} alt="Lunch" className="img" />
                  <div className="txt1">{item.title}</div>
                  <div className="txt2">{ReactHtmlParser(item.desc)}</div>
                  {item.link ? (
                    <div className="txt3">
                      <Link to={item.link} style={{ color: "#ffffff" }}>
                        Läs mer
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="banner_bottom"></div>
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BoxItem;
