import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const d = new Date();
  const year = d.getFullYear();
  return (
    <div className="bot1_wrapper">
      <div className="container">
        <div className="row">
          <div className="span12">
            <div className="bot1 clearfix">
              <div className="menu_bot">
                <ul id="menu_bot" className="clearfix">
                  <li className="active">
                    <Link to="/">Hem</Link>
                  </li>
                  <li>
                    <Link to="/alacarte">Á la carte</Link>
                  </li>
                  <li>
                    <Link to="/even-lunch">Lunch - Jämna V.</Link>
                  </li>
                  <li>
                    <Link to="/lunch">Lunch - Udda V.</Link>
                  </li>
                  <li>
                    <Link to="/takeaway">Take Away</Link>
                  </li>
                  {/* <li>
                    <Link to="/drycker">Drycker</Link>
                  </li>*/}
                  <li>
                    <Link to="/kontakt">Kontakt</Link>
                  </li>
                </ul>
              </div>
              <footer>
                Copyright © {year} indianrasoi.se. All rights reserved.
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
