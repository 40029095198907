import React from "react";
import Menu from "../components/Menu";
import { warm, alcholFree } from "../constants/drinks";
import useBodyClass from "../customHooks/UseBodyClass";
import PageSlider from "../components/PageSlider";
import { MenuTemplate } from "../components/MenuTemplate";

/* eslint-disable react/prop-types */
function Drink() {
  useBodyClass(`not-front`);
  const Bar = props => {
    return (
      <>
        {props.items.map((item, index) => {
          return <MenuTemplate {...item} key={index} />;
        })}
      </>
    );
  };
  return (
    <>
      <PageSlider imageNumber={4} title={"Drycker"} />
      <div id="content">
        <div className="container">
          <div className="row">
            <div className="span3">
              <div className="main1_wrapper">
                <Menu />
              </div>
            </div>
            <div className="span9">
              <h2 className="text-center">VARMA DRYCKER</h2>
              <Bar items={warm} />

              <div className="line1"></div>

              <h2 className="text-center">ALKOHOLFRITT</h2>
              <Bar items={alcholFree} />

              <div className="line1"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Drink;
