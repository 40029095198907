import React from "react";
import Menu from "../components/Menu";
import Box from "../components/Box/Index";
import useBodyClass from "../customHooks/UseBodyClass";
import Slider from "../components/Slider";
import "../styles/camera.css";

import ImportScript from "../customHooks/ImportScript";
const SliderJs = props => {
  ImportScript("/js/camera.js");
  ImportScript("/js/camera.slider.js");
};

function Home() {
  useBodyClass(`front`);
  SliderJs();
  return (
    <>
      <div className="slider_wrapper2">
        <Slider />
      </div>
      <div id="content">
        <div className="container">
          <div className="row">
            <div className="span3">
              <div className="main1_wrapper">
                <Menu />
              </div>
            </div>
            <div className="span9">
              <div className="banners">
                <Box />
              </div>

              <h2>Välkommen Till Restaurang Indian Rasoi</h2>

              <div className="thumb1">
                <div className="thumbnail clearfix">
                  <figure className="img-polaroid">
                    <img src="images/hem.jpg" alt="Välkommen" />
                  </figure>
                  <div className="caption">
                    <p className="upper">Varmt Välkommnen!</p>
                    <p>
                      Vi vill välkommna er till vår trevliga indiska restaurang
                      där våra besökare alltid kommer i första hand. Vi
                      garanterar att ni kommer få njuta av äkta indisk mat av
                      kvalitet och färska råvaror direkt från Indien.
                    </p>
                    <p>
                      Hos oss lagas all mat från grunden. Färska råvaror av
                      bästa kvalitet bereds och tillreds i den rika variation av
                      kryddor som det indiska köket erbjuder.
                    </p>
                    <p>
                      Vi hoppas att ni kommer njuta av maten och rekommenderar
                      oss.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
