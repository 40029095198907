export const warm = [
  {
    id: "01",
    name: "Te/Green Te/ Kafé",
    desc: "",
    price: 20
  },
  {
    id: "02",
    name: "Indisk Masala Chai",
    desc:
      "Te som kokat tillsammans med mjölk, kardemumma, ingefära och kryddnejlika.",
    price: 25
  }
];

export const alcholFree = [
  {
    id: "01",
    name: "Mango lassi eller salt lassi",
    desc: "Yoghurtdryck med mangosmak eller kryddsaltad lassi",
    price: 25
  },
  {
    id: "02",
    name: "Cola/Fanta/Sprite",
    desc: "",
    price: 15
  },
  {
    id: "03",
    name: "Kolsyrat vatten",
    desc: "",
    price: 10
  },
  {
    id: "04",
    name: "Kolsyrat vatten med fruktsmak",
    desc: "",
    price: 10
  },
  {
    id: "05",
    name: "Lättöl",
    desc: "",
    price: 15
  },
  {
    id: "05",
    name: "Alkholfritt öl",
    desc: "",
    price: 25
  }
];
