import React from "react";
import Menu from "../components/Menu";
/*import {
  info,
  evenMonday,
  evenTuesday,
  evenThursday,
  evenWednesday,
  evenFriday
} from "../constants/lunch";*/
import useBodyClass from "../customHooks/UseBodyClass";
import PageSlider from "../components/PageSlider";
//import { LunchMenuTemplate } from "../components/MenuTemplate";
//import ReactHtmlParser from "react-html-parser";

/* eslint-disable react/prop-types */
function EvenLunch() {
  useBodyClass(`not-front`);
  /*const Day = props => {
    return (
      <>
        {props.items.map((item, index) => {
          return <LunchMenuTemplate {...item} key={index} />;
        })}
      </>
    );
  };*/
  return (
    <>
      <PageSlider imageNumber={4} title={"Lunch - Jämna Veckor"} />
      <div id="content">
        <div className="container">
          <div className="row">
            <div className="span3">
              <div className="main1_wrapper">
                <Menu />
              </div>
            </div>
            <div className="span9">
              <img
                src="images/lunch/even-lunch-2024-01.jpg"
                alt="Jämna Veckor Lunch Meny"
                className="img"
              />
              <img
                src="images/lunch/even-lunch-2024-02.jpg"
                alt="Jämna Veckor Lunch Meny"
                className="img"
              />
              {/*
              <p>
                <em>
                  <strong>{info.desc}</strong>
                </em>
              </p><p>{ReactHtmlParser(info.extra)}</p>
              <h2 className="text-center">Måndag</h2>
              <Day items={evenMonday} />

              <div className="line1"></div>

              <h2 className="text-center">Tisdag</h2>
              <Day items={evenTuesday} />

              <div className="line1"></div>

              <h2 className="text-center">Onsdag</h2>
              <Day items={evenWednesday} />

              <div className="line1"></div>

              <h2 className="text-center">Torsdag</h2>
              <Day items={evenThursday} />

              <div className="line1"></div>

              <h2 className="text-center">Fredag</h2>
              <Day items={evenFriday} />

              <div className="line1"></div>*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EvenLunch;
