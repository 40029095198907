import React from "react";
import PageNotFound from "../page-not-found.jpg";

class NotFound extends React.Component {
  render() {
    return (
      <div className="container" style={{ textAlign: "center" }}>
        <img
          src={PageNotFound}
          alt="404 Page not found"
          style={{
            width: "100%",
            maxWidth: "720px"
          }}
        />
      </div>
    );
  }
}
export default NotFound;
