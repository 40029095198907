import React from "react";
import Menu from "../components/Menu";
import useBodyClass from "../customHooks/UseBodyClass";
import PageSlider from "../components/PageSlider";

function Location() {
  useBodyClass(`not-front`);
  return (
    <>
      <PageSlider imageNumber={1} title={"Hitta Oss"} />
      <div id="content">
        <div className="container">
          <div className="row">
            <div className="span3">
              <div className="main1_wrapper">
                <Menu />
              </div>
            </div>
            <div className="span9">
              <h2 className="text-center">Adress</h2>

              <figure className="google_map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2129.8233973332385!2d11.90895175150021!3d57.73644168103748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464f8b20778eb145%3A0x39bfe4b8e0c9f88c!2sIndian%20rasoi!5e0!3m2!1ssv!2sse!4v1590060710255!5m2!1ssv!2sse"
                  frameBorder="0"
                  aria-hidden="false"
                  tabIndex="0"
                  title="Adress"
                ></iframe>
              </figure>

              <p>
                <strong className="upper">Indian Rasoi</strong>
              </p>
              <p>
                Björlandavägen 207
                <br /> 417 29 Göteborg
                <br /> Telephone: 031- 13 53 75
                <br /> E-mail:&nbsp;
                <a href="mailTo:info@indianrasoi.se" target="_top">
                  info@indianrasoi.se
                </a>
              </p>

              <div className="line1"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Location;
