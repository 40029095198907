import React, { useState } from "react";
import axios from "axios";
import Menu from "../components/Menu";
import useBodyClass from "../customHooks/UseBodyClass";
import PageSlider from "../components/PageSlider";

function validateEmail(email) {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
  return re.test(String(email).toLowerCase());
}

function validateForm(values) {
  const errors = {};
  if (!values.name) errors.name = "Name is required"; // pretty standard error messages. cuz im too lazy to think
  if (!values.email) errors.email = "Email address is required";
  else if (!validateEmail(values.email))
    errors.email = "Not a valid email address";
  if (!values.message) errors.message = "Message is required";

  return errors;
}

function useInputValue(initialValue) {
  const [value, setValue] = useState(initialValue);

  function retrieveValue(event) {
    setValue(event.target.value);
  }
  return [value, retrieveValue];
}

function Contact() {
  useBodyClass(`not-front`);
  const [name, setName] = useInputValue("");
  const [email, setEmail] = useInputValue("");
  const [message, setMessage] = useInputValue("");
  const [statusMsg, setStatusMsg] = useState("sending...");
  const [errors, setErrors] = useState({});
  const [isSend, setIsSend] = useState(false);

  function handleSubmit() {
    const errors = validateForm({ name, email, message });
    setErrors(errors);
    if (!Object.keys(errors).length) {
      doContact({ name, email, message });
    }
  }

  async function doContact(contactInfo) {
    setIsSend(true);
    await axios({
      method: "POST",
      url: "http://indianrasoi.se/email/index.php",
      data: contactInfo
    }).then(response => {
      if (response.data.status === "success") {
        setStatusMsg("Message Sent Successfully.");
      } else if (response.data.status === "fail") {
        setStatusMsg(
          "Message failed to send. Please try sending email directly to info@indianrasoi.se or call for booking."
        );
      } else {
        setStatusMsg(
          "Some error occured. Please try sending email directly to info@indianrasoi.se or call for booking."
        );
      }
    });
  }

  return (
    <>
      <PageSlider imageNumber={1} title={"Kontakt Information"} />
      <div id="content">
        <div className="container">
          <div className="row">
            <div className="span3">
              <div className="main1_wrapper">
                <Menu />
              </div>
            </div>
            <div className="span9">
              <h2 className="text-center">Adress</h2>

              <figure className="google_map2">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2129.8233973332385!2d11.90895175150021!3d57.73644168103748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464f8b20778eb145%3A0x39bfe4b8e0c9f88c!2sIndian%20rasoi!5e0!3m2!1ssv!2sse!4v1590060710255!5m2!1ssv!2sse"
                  title="Adress"
                ></iframe>
              </figure>

              <p>
                <strong className="upper">Indian Rasoi</strong>
              </p>
              <p>
                Björlandavägen 207
                <br /> 417 29 Göteborg
                <br /> Telephone: 031- 13 53 75
                <br /> E-mail:&nbsp;
                <a href="mailTo:info@indianrasoi.se" target="_top">
                  info@indianrasoi.se
                </a>
              </p>

              <div className="line1"></div>
              <h3 className="text-center">Kontakta oss</h3>

              {isSend ? (
                <div className="alert">{statusMsg}</div>
              ) : (
                <div id="ajax-contact-form">
                  <div className="block1 clearfix">
                    <div className="block3">
                      <div
                        className={
                          "control-group " +
                          (errors.name ? "warning" : "success")
                        }
                      >
                        <div className="controls">
                          <input
                            type="text"
                            name="name"
                            placeholder="Namn"
                            onChange={setName}
                            value={name}
                            noValidate
                          />
                          {errors.name && (
                            <span className="help-inline">{errors.name}</span>
                          )}
                        </div>
                      </div>
                      <div
                        className={
                          "control-group " +
                          (errors.email ? "warning" : "success")
                        }
                      >
                        <div className="controls">
                          <input
                            type="email"
                            name="email"
                            placeholder="E-post"
                            onChange={setEmail}
                            value={email}
                            noValidate
                          />
                          {errors.email && (
                            <span className="help-inline">{errors.email}</span>
                          )}
                        </div>
                      </div>
                      <div
                        className={
                          "control-group " +
                          (errors.message ? "warning" : "success")
                        }
                      >
                        <div className="controls">
                          <textarea
                            name="message"
                            rows="6"
                            placeholder="Message"
                            onChange={setMessage}
                            value={message}
                            noValidate
                          ></textarea>
                          {errors.message && (
                            <span className="help-inline">
                              {errors.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <button className="submit" onClick={handleSubmit}>
                    Skicka
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
