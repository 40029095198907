/* eslint-disable react/prop-types */
import React from "react";

const PageSlider = ({ imageNumber, title }) => {
  return (
    <div className="page_slide_wrapper">
      <div className="page_slide">
        <img
          src={`images/page_slide${imageNumber}.jpg`}
          alt={title}
          className="img"
        />
      </div>
      <div className="breadcrumbs1_wrapper">
        <div className="container">
          <div className="row">
            <div className="span9 offset3">
              <div className="breadcrumbs1">
                <div className="txt1">{title}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageSlider;
