import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";

import Footer from "./components/Footer";
import Lunch from "./pages/Lunch";
import EvenLunch from "./pages/EvenLunch";
import Alacarte from "./pages/Alacarte";
import Takeaway from "./pages/Takeaway";
import News from "./pages/News";
import Location from "./pages/Location";
import Contact from "./pages/Contact";
import Drink from "./pages/Drink";
import NotFound from "./pages/NotFound";
import GA from "./utils/GoogleAnalytics";

function App() {
  return (
    <Router>
      <div id="main">
        {GA.init() && <GA.RouteTracker />}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/om-oss" component={About} />
          <Route path="/nyheter" component={News} />
          <Route path="/alacarte" component={Alacarte} />
          <Route path="/lunch" component={Lunch} />
          <Route path="/even-Lunch" component={EvenLunch} />
          <Route path="/takeaway" component={Takeaway} />
          <Route path="/drycker" component={Drink} />
          <Route path="/hitta-oss" component={Location} />
          <Route path="/kontakt" component={Contact} />
          <Route component={NotFound} />
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
