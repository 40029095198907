import React from "react";

function Slider() {
  return (
    <div className="slider_wrapper">
      <div id="slider">
        <div id="camera_wrap">
          <div data-src="images/slide01.jpg">
            <div className="camera_caption fadeIn">
              <div className="txt1">Autentisk indisk restaurang</div>
            </div>
          </div>
          <div data-src="images/slide02.jpg">
            <div className="camera_caption fadeIn">
              <div className="txt1">Indian Rasoi</div>
            </div>
          </div>
          <div data-src="images/slide03.jpg">
            <div className="camera_caption fadeIn">
              <div className="txt1">Vi skapar läckra upplevelser</div>
            </div>
          </div>
          <div data-src="images/slide04.jpg">
            <div className="camera_caption fadeIn">
              <div className="txt1">Autentisk indisk restaurang</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Slider;
