import React from "react";
import { Link } from "react-router-dom";
import "../styles/Menu.css";

import ImportScript from "../customHooks/ImportScript";
const SuperFish = props => {
  ImportScript("/js/superfish.js");
};

function Menu() {
  SuperFish();
  return (
    <div className="main1">
      <div className="main1_top"></div>
      <div className="main1_inner">
        <header>
          <div className="logo_wrapper">
            <Link to="/" className="logo">
              <img src="images/indian-rasoi.png" alt="Indian Rasoi" />
            </Link>
          </div>
        </header>

        <div className="navbar navbar_">
          <div className="navbar-inner navbar-inner_">
            <a
              href="/#"
              className="btn btn-navbar btn-navbar_"
              data-toggle="collapse"
              data-target=".nav-collapse_"
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </a>
            <div className="nav-collapse nav-collapse_ collapse">
              <ul className="nav sf-menu clearfix">
                <li className="active">
                  <Link to="/">Hem</Link>
                </li>
                <li>
                  <Link to="/om-oss">Om Oss</Link>
                </li>
                <li className="sub-menu sub-menu-1">
                  <Link to="/alacarte">Meny</Link>
                  <ul>
                    <li>
                      <Link to="/alacarte">Á la carte</Link>
                    </li>
                    <li className="sub-menu sub-menu-2">
                      <Link to="/lunch">Lunch - Udda Veckor</Link>
                    </li>
                    <li className="sub-menu sub-menu-2">
                      <Link to="/even-lunch">Lunch - Jämna Veckor</Link>
                    </li>
                    <li className="sub-menu sub-menu-2">
                      <Link to="/takeaway">Take away</Link>
                    </li>
                    {/*
                    <li className="sub-menu sub-menu-2">
                      <Link to="/drycker">Drycker</Link>
                    </li>
                    */}
                  </ul>
                </li>
                <li>
                  <Link to="/nyheter">Nyheter</Link>
                </li>
                <li>
                  <Link to="/hitta-oss">Hitta Oss</Link>
                </li>
                <li>
                  <Link to="/kontakt">Kontakt</Link>
                </li>
              </ul>

              <div className="kand1"></div>

              <div className="opening">
                <div className="txt1">Öppettider:</div>
                <div className="txt2">
                  Måndag - Fredag
                  <br /> 11:00 - 22:00
                  <br />
                  <br />
                  Lördag
                  <br /> 13:00 - 22:00
                  <br />
                  <br />
                  Söndag
                  <br /> 13:00 - 21:00
                </div>
              </div>

              <div className="pad_bot1"></div>

              <div className="opening">
                <div className="txt1">Adress:</div>
                <div className="txt2">
                  Björlandavägen 207 <br />
                  417 29 Göteborg
                </div>
              </div>

              <div className="pad_bot1"></div>

              <div className="kand2"></div>

              <div className="phone1">
                <div className="txt1">Boka bord</div>
                <div className="txt2">031- 13 53 75</div>
              </div>

              <div className="kand3"></div>

              <div className="wifi1">
                <img src="images/wifi.png" alt="Free wifi" className="img" />
              </div>

              <div className="social_wrapper">
                <div className="txt1">Följ oss:</div>

                <ul className="social clearfix">
                  <li>
                    <a
                      href="https://www.facebook.com/Restaurang-Masala-Rasoi-106831701035343/"
                      title="facebook sida"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="images/social_ic1.png" alt="facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <img src="images/social_ic2.png" alt="LinkedIn" />
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <img src="images/social_ic3.png" alt="Twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <img src="images/social_ic4.png" alt="Youtube" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menu;
