import React from "react";
/* eslint-disable react/prop-types */
export const MenuTemplate = ({ name, id, desc, price }) => (
  <div className="thumb1">
    <div className="thumbnail clearfix">
      <div className="caption">
        <p>
          <strong className="upper">
            {id}. {name}
          </strong>
          {desc ? " -" : ""}
          <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            {desc}
          </span>
          -
          <span className="upper" style={{ paddingLeft: "10px" }}>
            <em>{price} kr</em>
          </span>
        </p>
      </div>
    </div>
  </div>
);

export const LunchMenuTemplate = ({ name, id, desc }) => (
  <div className="thumb1">
    <div className="thumbnail clearfix">
      <div className="caption">
        <p>
          <strong className="upper">
            {id}. {name}
          </strong>
          {desc ? " -" : ""}
          <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            {desc}
          </span>
        </p>
      </div>
    </div>
  </div>
);
