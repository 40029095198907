import React from "react";
import Menu from "../components/Menu";
import useBodyClass from "../customHooks/UseBodyClass";
import PageSlider from "../components/PageSlider";

function News() {
  useBodyClass(`not-front`);
  return (
    <>
      <PageSlider imageNumber={2} title={"Nyheter"} />
      <div id="content">
        <div className="container">
          <div className="row">
            <div className="span3">
              <div className="main1_wrapper">
                <Menu />
              </div>
            </div>
            <div className="span9">
              <h2 className="text-center">Nyheter</h2>

              <div className="thumb1">
                <div className="thumbnail clearfix">
                  <div className="caption">
                    <p
                      className="upper"
                      style={{
                        textAlign: "center"
                      }}
                    >
                      <p>
                        <b>
                          15% rabatt på hela notan under hela januari och
                          feburari månad (2024).
                          <br /> Gäller endast Ala Carte och Take away meny.
                          <br /> Gäller ej alkohol/drycker
                        </b>
                      </p>
                      <br />
                      <img
                        src="images/namaste.jpg"
                        alt="Namaste!"
                        style={{
                          width: "130px",
                          height: "150px"
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>

              <div className="line1"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default News;
