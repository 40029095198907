import React from "react";
import Menu from "../components/Menu";
import useBodyClass from "../customHooks/UseBodyClass";
import PageSlider from "../components/PageSlider";

function About() {
  useBodyClass(`not-front`);
  return (
    <>
      <PageSlider imageNumber={1} title={"Om Oss"} />
      <div id="content">
        <div className="container">
          <div className="row">
            <div className="span3">
              <div className="main1_wrapper">
                <Menu />
              </div>
            </div>
            <div className="span9">
              <h2 className="text-center">Indiska köket</h2>

              <div className="thumb1">
                <div className="thumbnail clearfix">
                  <div className="caption">
                    <p>
                      Vi vill välkommna er till vår trevliga indiska restaurang
                      där våra besökare alltid kommer i första hand. Vi
                      garanterar att ni kommer få njuta av äkta indisk mat av
                      kvalitet och färska råvaror direkt från Indien.
                    </p>
                    <p>
                      Hos oss lagas all mat från grunden. Färska råvaror av
                      bästa kvalitet bereds och tillreds i den rika variation av
                      kryddor som det indiska köket erbjuder.
                    </p>
                    <p>
                      Men vi hoppas att ni kommer att njuta av vår mat och
                      vänlighet. Vi vill inte att några av våra gäster går hem
                      hungriga från restaurang Indian Rasoi.
                    </p>
                    <p
                      className="upper"
                      style={{
                        textAlign: "center"
                      }}
                    >
                      Tack för att ni besöker oss och välkommen åter! <br />
                      <br />
                      <img
                        src="images/namaste.jpg"
                        alt="Namaste!"
                        style={{
                          width: "130px",
                          height: "150px"
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>

              <div className="line1"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
