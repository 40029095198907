/* eslint-disable react/prop-types */
import React from "react";
import Menu from "../components/Menu";
/*import {
  starters,
  grills,
  shrimps,
  chickens,
  lambs,
  vegs,
  extras,
  deserts
} from "../constants/alacarte";*/
//import { MenuTemplate } from "../components/MenuTemplate";
import useBodyClass from "../customHooks/UseBodyClass";
import PageSlider from "../components/PageSlider";
import SinglePagePDFViewer from "../components/pdf/single-page";
import AllPagesPDFViewer from "../components/pdf/all-pages";
import "../styles/pdf.css";
import { Responsive } from "../components/Responsive";

function Alacarte() {
  const menuPDF = process.env.PUBLIC_URL + "/pdf/rasoi-alacarte-2024.pdf";
  useBodyClass(`not-front`);
  /*const MenuSection = props => {
    return (
      <>
        {props.items.map((item, index) => {
          return <MenuTemplate {...item} key={index} />;
        })}
      </>
    );
  };*/
  return (
    <>
      <PageSlider imageNumber={1} title={"Á la carte"} />
      <div id="content">
        <div className="container">
          <div className="row">
            <div className="span3">
              <div className="main1_wrapper">
                <Menu />
              </div>
            </div>
            <div className="span9 d-none d-sm-block d-md-none">
              <Responsive displayIn={["Laptop"]}>
                <SinglePagePDFViewer pdf={menuPDF} />
              </Responsive>
              <Responsive displayIn={["Mobile"]}>
                <AllPagesPDFViewer pdf={menuPDF} />
              </Responsive>

              {/*<h2 className="text-center">FÖRRÄTTER</h2>
              <MenuSection items={starters} />
              <div className="line1"></div>
              <h2 className="text-center">Grillat</h2>
              <MenuSection items={grills} />
              <div className="line1"></div>
              <h2 className="text-center">Räkor</h2>
              <MenuSection items={shrimps} />
              <div className="line1"></div>
              <h2 className="text-center">Kyckling</h2>
              <MenuSection items={chickens} />
              <div className="line1"></div>
              <h2 className="text-center">Lamm</h2>
              <MenuSection items={lambs} />
              <div className="line1"></div>
              <h2 className="text-center">Vegetariskt</h2>
              <MenuSection items={vegs} />
              <div className="line1"></div>
              <h2 className="text-center">Tillbehör</h2>
              <MenuSection items={extras} />
              <div className="line1"></div>
              <h2 className="text-center">Efterrätter</h2>
              <MenuSection items={deserts} />
  <div className="line1"></div>*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Alacarte;
